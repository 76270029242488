import Layout from "./Layout";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import styled from "styled-components";
import SEO from "./seo";

const Container = styled.div`
  margin-bottom: 15px;
  font-size: 12px;
  text-align: justify;
  text-align: 20px;
  line-height: 18px;
`;

const StyledLink = styled.a`
  color: #027bb6;
`;

export { Layout, Header, Footer, SEO, Container, StyledLink };
