import React from "react";
import styled from "styled-components";
import { mq } from "../../../styles"

const QuoteMark = styled.span`
  font-size: 90px;
  pointer-events: none;
  margin-top: -1rem;
  line-height: 0;
  color: #cccccc;
  transform: translateY(calc(50% + 20px));
  
  @media screen and ${mq.minSm} {
    padding: 0 20px;
    transform: translateY(70%);
    font-size: 15rem;
  }
`;

const QuoteBlock = styled.blockquote`
  border: 0;
  padding-left: 0;
  font-size: 24px;
  line-height: 1.2;
  color: #757575;
  font-weight: bold;
  text-align: center;
  margin: 0;
  
  @media screen and ${mq.minSm} {
    margin: 2rem 5%;
    font-size: 26px;
  }
`;

const Quote = ({children, attribute}) => {

  return (
    <div className={'quote container'} style={{
      display: "flex",
    }}>
      <QuoteMark aria-hidden>&ldquo;</QuoteMark>
      <QuoteBlock>
        { children }
        { ( attribute ? <span style={{ fontWeight: 400, paddingLeft: 8 }}>- { attribute }</span> : '' ) }
      </QuoteBlock>
      <QuoteMark aria-hidden>&rdquo;</QuoteMark>
    </div>
  )
}

export default Quote;
