import styled, { css } from "styled-components"
import PropTypes from "prop-types";
import GatsbyImage from "gatsby-image"
import { brand, mq } from "../../../styles"
import React from "react"
import { graphql } from "gatsby"

const Tile = styled.div`
  text-align: center;
  position: relative;
  border-radius: 25px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  z-index: 500;
  justify-content: center;
  background-color: #ffffff;

  ${props => props.CSTile && css`
    
  h4 {
    margin-bottom: 30px;
  }
  `}

  // ${props => props.lcrPage && css`
  // @media screen and ${mq.maxMd} {
  //   height: 260px;
  // }
  // `}
  ${props => props.perks && css`
    padding: 50px;

    h4 {
      padding: 0 20px;
    }

    p {
      font-size: 19px;
    }
  `}
`;

const TileHeading = styled.h6`
  margin-bottom: .7rem;
  color: #333;
  white-space: pre-wrap;
  
  
`;

const TileIcon = styled.div`
  text-align: center;
  margin-bottom: 25px;
  left: 50%;
  white-space: pre-wrap;
`;

const TileContent = styled.div`
  white-space: pre-wrap;
  font-size: 19px;
  > *:last-child {
    margin-bottom: 0;
  }
`



const IconTile = ({ title, icon, children, border, card, imgConStyle, imgStyle, ...props }) => {

  return (
    <Tile card={card} {...props}>
      { ( icon?.childImageSharp?.fixed ? <TileIcon className={'icon'}>
        <GatsbyImage style= {imgConStyle ? imgConStyle : { height: 100, width: 100 }} fixed={icon.childImageSharp.fixed} imgStyle = {imgStyle} />
      </TileIcon> : '' ) }
      <div className={'tileimagecontent'}>
      {/* { (contained ? <div className="container">{children}</div> : {children}) } */}
      { ( title ? <TileHeading>{title}</TileHeading> : '' ) } 
        <TileContent>{children}</TileContent>
      </div>
    </Tile>
  )
}

export default IconTile;

IconTile.propTypes = {
  border: PropTypes.bool,
  title: PropTypes.string.isRequired
}

IconTile.defaultProps = {
  border: true
}

export const query = graphql`
  fragment IconTileImage on File {
    childImageSharp {
      fixed(width: 200, height: 200, webpQuality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`;
