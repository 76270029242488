import React from "react";
import styled from 'styled-components';
import { Content } from "../";
import GatsbyImage from "gatsby-image"
import { mq } from "../../../styles"
import { graphql } from "gatsby"

const IconCalloutWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  max-width: 970px;
  margin: 30px auto;
  
  @media screen and ${mq.minMd} {
    flex-direction: row;
  }
  
  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

const IconBox = styled.div`
  text-align: center;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-self: auto;
`;

const CallOutText = styled.div`
  padding-right: 40px;
  @media screen and ${mq.maxMd} {
    padding-right: 0;
  }
  *:last-child { margin-bottom: 0 }

  >h2 {
    text-align: left !important;
    font-size: 40px;
    @media screen and ${mq.maxMd} {
      text-align: center !important;
    }
  }
  >p {
    text-align: left !important;
    @media screen and ${mq.maxMd} {
      text-align: center !important;
    }
  }
  
`;

const IconCallout = ({icon,children, attribute, ...props}) => {

  return (
    <Content gradient>
      <IconCalloutWrapper {...props}>
        <CallOutText>
          { children }
        </CallOutText>
        { (icon ? <IconBox><GatsbyImage fixed={icon.childImageSharp.fixed} /></IconBox> : '') }
      </IconCalloutWrapper>
    </Content>
  )
}

export default IconCallout;

export const query = graphql`
  fragment IconCalloutImage on File {
    childImageSharp {
      fixed(width: 150, height: 150, webpQuality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`;
