import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { mq } from "../../../styles"
import GatsbyImage from "gatsby-image"

const LogoContainer = styled.div`

@media screen and (min-width: 1235px) {
  position: absolute;
  left: 5%;
  // z-index: 600;
  // transform: translateX(-50%);
}
  @media screen and (min-width: 992px) and (max-width: 1234px) {
    // left: 0%;
    // position: absolute;
    // // z-index: 600;
    // // transform: translateX(-45%);
    // right: 0%
    margin-left: 10px;
  }
  @media screen and (max-width: 991px)  {
    margin-left: 20px;
  }
  .logo-link-container {
    padding: 5px;
    z-index: 999;
    position: relative;
    display: block;
    margin-right: 1rem;
    margin-top: .5rem;
    @media screen and ${mq.minMd}{
      margin: 0;
      left: 3%;
    }
  }
`;


const Logo = ({wallet}) => {

  const walletQuery = useStaticQuery(graphql`
  query {
    plusgrade: file(base: {eq: "Points_PG_Dark.png" }){
      childImageSharp {
        fixed(width: 150, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    plusgradeInv: file(base: {eq: "Points_PG_White.png" }){
      childImageSharp {
        fixed(width: 150, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
    `);
    return(
      <LogoContainer>
        <Link className={"logo-link-container"} to={'/'} title={"Points.com"}>
          {
            
            <div>
          <GatsbyImage className="dark-logo" fixed={ walletQuery.plusgrade.childImageSharp.fixed }
              objectFit="contain"
              alt="Points.com Logo"
              imgStyle={{
                objectFit: 'contain'
              }}
          />
          </div>
          }
        </Link>
      </LogoContainer>
    )
}

export default Logo
