import styled, { css } from "styled-components"
import PropTypes from "prop-types";
import GatsbyImage from "gatsby-image"
import { brand, mq } from "../../../styles"
import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby";

const Card = styled.div`
    border-radius: 26px;
    background-color: #fff;
    width: auto;
    border-bottom-right-radius: 26px;
    border-bottom-left-radius: 26px;
    pointer-events: none;
    flex-basis: 315px;
    width: 315px;
    height: 362px;

    @media only screen and ${mq.maxMd}{
        max-width: 305px;
        margin: 20px 0;
      }

    .blog-link {
        cursor: pointer;
        pointer-events: all;
        .gatsby-image-wrapper {
            width: 100%;
            height: 150px;
            border-top-right-radius: 26px;
            border-top-left-radius: 26px;
        }
        .blog-card-copy {
            text-align: center;
            padding: 25px 52px;
            font-weight: 900;
            color: #333333;
            font-size: 20px;
            &:hover{
                color: #286ff7;
            }
        }

        &:hover {
            text-decoration: none;
            
        }
    }
`;

const BlogCard = ({image, copy, imgConStyle, imgStyle, toURL, ...props }) => {

    return (
        <Card>
            <a className={"blog-link"} href={toURL}  rel="noopener noreferrer">
                <GatsbyImage fixed={image.childImageSharp.fluid} style={imgConStyle} imgStyle = {imgStyle} />
                <div className={"blog-card-copy"}>
                    {copy}
                </div>
            </a>
        </Card>
     
    )
  }
  
  export default BlogCard;