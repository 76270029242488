import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { brand, mq } from "../../../styles";
import GatsbyImage from "gatsby-image";
import { graphql } from "gatsby";

const Subtitle = styled.p`
  color: #fff;
  font-size: 18px;
  margin-top: 1rem;
  font-weight: 500;
  margin-bottom: 0;
`;

const Inner = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 25px;
  z-index: 500;
  transition: all 0.8s ease;
  text-align: center;
`;

const Container = styled.section`
  position: relative;
  background-image: ${brand.gradient};
  padding: 150px 1rem 0px;
  margin-bottom: 50px;
  height: 520px @media screen and ${mq.minMd} {
    padding-right: calc((100vw - 1120px) / 2);
    padding-left: calc((100vw - 1120px) / 2);
  }

  .content {
    padding-bottom: 50px;
    max-width: 450px;
    padding: 0 2rem 50px;

    @media screen and ${mq.minMd} {
      ${props =>
        props.vulnerability &&
        css`
          max-width: 1080px !important;
        `}
      max-width: 65%;
      padding: 0 1.5rem 50px;
      font-size: 24px;
      max-width: 450px;
    }
  }

  ${props =>
    props.image &&
    css`
      padding-bottom: 25px;
      margin-bottom: 0;

      @media screen and ${mq.maxMd} {
        padding-top: 175px;
        padding-bottom: 50px;
      }
      @media screen and ${mq.maxSm} {
        padding-top: 150px;
        padding-bottom: 30px;
      }

      .content {
        padding-bottom: 0;
        max-width: 620px;
      }
    `}
`;

const HeroTitle = styled.h1`
  white-space: pre-wrap;
  color: #fff;
  font-weight: 900;
  line-height: 1.1;
  letter-spacing: -0.2px;
  margin-bottom: 2rem;
  max-width: 100%;

  @media screen and ${mq.minSm} {
    font-size: 50px;
    margin-bottom: 0;
  }
`;

/**
 * This is our page header component.
 */
const Hero = ({ image, title, subtitle, alt, ...props }) => {
  const img = image ? <HeroImage alt={alt} image={image} /> : "";

  const subtitleElm = subtitle ? <Subtitle>{subtitle}</Subtitle> : "";

  return (
    <Container image={image} {...props}>
      <Inner>
        <div className="content">
          <HeroTitle>{title}</HeroTitle>
          {subtitleElm}
        </div>
        {img ? img : <></>}
      </Inner>
    </Container>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  align: PropTypes.string,
  image: PropTypes.object,
};

Hero.defaultProps = {
  align: "center center",
};

export default Hero;

export { Hero };

const HeroImageContainer = styled.div`
  height: 100%;
  filter: drop-shadow(-1px 6px 3px rgba(0, 0, 0, 0.5));
  position: relative;
  display: none !important;

  @media screen and ${mq.minMd} {
    display: block !important;
  }

  .gatsby-image-wrapper {
    clip-path: url(#svgPath);
  }
`;

const HeroImageStyles = {
  maxWidth: "450px",
  maxHeight: "355px",
  right: 0,
  borderRadius: "25px",
  overflow: "hidden",
};

const HeroImage = ({ image, alt }) => {
  if (image?.childImageSharp?.fixed) {
    return (
      <HeroImageContainer>
        <GatsbyImage
          style={HeroImageStyles}
          imgStyle={{
            height: "100%",
            objectFit: "cover",
            objectPosition: "60% 50%",
          }}
          alt={alt}
          fixed={image.childImageSharp.fixed}
        />
      </HeroImageContainer>
    );
  } else {
    return <></>;
  }
};

HeroImage.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.shape({
    childImageSharp: PropTypes.object,
  }),
};

export const query = graphql`
  fragment HeroImage on File {
    childImageSharp {
      fixed(width: 450, height: 355, webpQuality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`;
