import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import GatsbyImage from "gatsby-image";
import { brand, mq } from "../../../styles"


const PartnerCardElement = styled.div`
  display: inline-block;
	margin-bottom: 1rem;
	width: 50%;
	text-align: center;

  a > .gatsby-image-wrapper > div {
    padding-bottom: 40% !important;
  }

	@media screen and (min-width: 700px) {
		width: 25%;
		margin-bottom: 3rem;
	}
`;

const PartnerCard = ({ partner }) => {
  return (
    <>
      <PartnerCardElement
        key={partner}
        data-partner-name={partner.readable}
      >
        <a  rel={'noopener noreferrer'} href={partner.website}>
          <GatsbyImage fluid={partner.partnerLogo.childImageSharp.fluid} alt={partner.readable} imgStyle={{
            objectFit: "none",
            objectPosition: "50% 50%",
          }}/>
        </a>
      </PartnerCardElement>
    </>
  );
};

PartnerCard.propTypes = {
  partner: PropTypes.object.isRequired,
  type: PropTypes.oneOf(["simple", "industry"]),
};

PartnerCard.defaultProps = {
  type: "simple",
};

export default PartnerCard;
