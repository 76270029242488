import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faChevronRight, faCoffee } from '@fortawesome/free-solid-svg-icons';
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { brand, mq } from "../../../styles";

const MenuContainer = styled.ul`
  padding: 0;
  list-style: none;
  position: absolute;
  // min-height: 100vh;
  display: none;
  top: 126px;
  background: #fff;
  border-bottom: 2px solid #eee;
  box-shadow: ${brand.dropShadow};
  color: #333333;
  overflow: hidden;
  margin-bottom:0;
  margin-right: 5%;
  font-weight: bold;
  float: right;
  @media screen and (max-width: 1234px) {
    margin-right: 3%
  }
  @media screen and ${mq.minMd} {
   
    background: transparent;
    min-height: auto;
    border: 0;
    position: relative;
    display: block;
    top: 0;
    box-shadow: none;
  
    // height: auto;
    // &:hover, :focus-within {
    //   color: #333333;
    // }
  }
  @media screen and (max-width: 991px){
    width: 85%;
    min-height: 55vh;
  }
  @media screen and (max-width: 480px) {
    top: 123px;
  }
  > li {
    display: block;
    max-width: 80%;
    margin: 35px 10px;
    // font-size: 20px;
    // contact us
    & :last-child{  
      > .top-level{  
        > a{
          border-radius: 25px;
        border-width: 5px;
       padding: 5px 30px;
        border: 2px solid #4DC9EE;
        text-align: center;
        @media screen and (max-width: 991px) {
          padding: 5px 10px;
          
        }
      }
      }
      @media screen and (max-width: 991px) {
        width: 220px;
      }
    }
    @media screen and ${mq.minMd} {
      display: inline-block;
      max-width: 100%;
    }
    &:not(:last-child):hover, :focus-within {
       &.Products-menu > .top-level > .menu-link {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 10px;
        border-radius: 5px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom: none;
        color: #226CF7;
        @media screen and (max-width: 991px) {
          border: none;
          box-shadow: none;
        }
      }
      .sub-menu {
        transform: translateX(0);
        &:after {
          opacity: 1;
        }
        @media screen and (max-width: 991px){
          display: block;
          position:relative;
         }
      }
    }
    
    &:first-child div {
      padding-left: 1rem;
      @media screen and (min-width: 1030px) {
        padding-left: 2rem;
      }
    }
    
    &.mobile-only {
      display: block;
      @media screen and ${mq.minMd} {
        display: none;
      }
    }
  }
  @media screen and (max-width: 991px) {
    right: 0px;
    margin: 0%;
    li{
      margin: 0px;
      max-width: 100%;
      .top-level{
        padding: 15px 1rem;
        .menu-link{
          padding: 0px;
          border: none;
          transition: all 0.5s ease 0s;
        }
      }
    }
    ${props => props.open && css`
      display: block;
      width: 85%;
      min-height: 55vh;
    `}
  }
  .Careers-menu{
    > .sub-menu {
    max-height: 155px;
    &:before{
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 63.5%;
    border: 0.01px outset rgba(0, 0, 0, 0.03);
    }
    @media screen and (max-width: 991px){
      
      &::before{
        content: "";
        border: none;
      }
    }
  }
  }
  
  
  .top-level {
    display: block;
    .menu-link{
      padding: 10px 5px;
      border: 2px solid #fff;
    }
    // min-width : 992px
    @media screen and ${mq.minMd} {
      
      display: inline-block;
      border: 0;
      max-width: 100%;
    } 
    // @media screen and (min-width: 1030px) {
    //   padding: 35px 10px;
    // }
    
    ${props => props.affixed && css`
      color: #333;
      
    `}
    // &:hover, :focus-within{
    //   + .sub-menu {
    //      transform: translateY(00);
    //      @media screen and (max-width: 991px){
    //       display: block;
    //       position:relative !important;
    //      }
    //   }
    // }
  
  }
  .sub-menu {
    overflow: hidden;
    background: #fff;
    margin: 0;
    height: auto;
    bottom: 0;
    max-height: 180px;
    position: fixed;
    transform: translateY(-180%);
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    list-style: none;
    width: 100%;
    max-width: 220px;
    border-radius: 5px;
    &:before{
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 59.5%;
      border: 0.01px outset rgba(0, 0, 0, 0.03);
    }
    &::-webkit-scrollbar {
      width: 0px;
    }
    @media screen and (max-width: 991px){
      display: none;
      right: 0px;
      padding: 10px 0px;
      max-height: 180px;
      // bottom: none;
      border: 1px solid #d9d9d9;
      border-left: none;
      border-right: none;
      border-radius: 0px;
      box-shadow: none;
      transform: none;
      // transition: all 0.5s ease 0s;
      list-style: none;
      max-width: 100%;
      // width: 50%;
      // top: 100px;
      // min-height: 100vh;
      // max-width: 550px;
     
      &::before{
        position: relative;
        content: "";
        border: none;
      }
      &&::after{
        
      }
     }
    li{
      background-color : #f2f6f9;
      padding: 10px 0px;
      display: flex;
      margin: 6px 5px 5px 5px;
      border-radius: 5px;
      align-items: center;
      justify-content: space-around;
      svg{
        color: #B9CEDD;
        font-size: 28px;
        margin-bottom: 8px;
        @media screen and (max-width: 991px){
          display: none;
        }
      }
      @media screen and (max-width: 991px){
        background-color : #fff;
        padding: 0px;
        margin: 0px;
        &::after {
          content: '';
        }
        a.menu-link{
          width: 100%;
        }
      }
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      transition: opacity .5s ease;
      right: 0;
      background: linear-gradient(to right, #0005, #0008 );
      top: 0;
      bottom: 0;
      overflow: hidden;
      transform: translate(100%);
      pointer-events: none;
      width: 100vw;
      opacity: 0;
      
      @media screen and ${mq.minMd} {
        top: 43px;
      }
    }
    
    @media screen and ${mq.minMd} {
      top: 80px;
      // box-shadow: none;
    }
    
    .menu-link {
      padding-left: 1rem;

    }
      
    span.menu-link {
      line-height: 1.2;
      color: #757575;
      margin-top: 2rem;
      margin-right: 4rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
    
    .heading:not(:first-child) {
      &:before {
        content: '';
        margin-top: 2rem;
        display: block;
        height: 2px;
        background: #eaeaea;
        margin-left: 1rem;
        margin-right: 2rem;
      }
    }
    
    a.menu-link {
      font-size: 20px;
      margin-bottom: 10px;
      line-height: 1.3;
      color: inherit;
      width: 70%;
      
      @media screen and ${mq.minMd} {
        font-size: 20px;
      }
      
      &:hover, :focus-within {
        color: #226CF7;
      }
    }
  }
  
  .menu-link {
    display: block;
    
    &:hover, :focus-within {
     text-decoration: none;
     color: #226CF7;
    }
    
    @media screen and ${mq.minMd} {
      top: 100px;
    } 
  }
`;

const Menu = ({data, affixed, open}) => (
  <nav style={{ zIndex: 600, width: '100%' }}>
    <MenuContainer open={open} affixed={affixed} id="menu">
      { data.map((item, index) => (
        <li key={index} className={(item.class ? item.class : '' )  + item.title + '-menu'}>
          <div className={'top-level '}><MenuLink item={item} /></div>
          { item.submenu ? generateSubMenu(item.submenu) : '' }
        </li>
      ))}
    </MenuContainer>
  </nav>
);

export default Menu;

const generateSubMenu = (items) => (
  <ul className={"sub-menu "}>
    { items.map((item, index) => (
      <li key={index} className={item.url ? '' : 'heading' }><MenuLink item={item}/><FontAwesomeIcon icon={faChevronRight} /></li>
    )) }
  </ul>
);

const MenuLink = ({item}) => {
  if(item.url) {
    if(!item.target) {
      return <Link className={'menu-link'} to={item.url}>{ item.title }</Link>
    } else {
      return <a className={'menu-link external'} data-temp={item.target} target={item.target} rel={'noreferrer'} href={item.url}>{ item.title }</a>
    }

  } else {
    return <span className={'menu-link' }>{ item.title.split('\n').map((str, key) => <div key={key}>{ str }</div>) }</span>
  }
}
