import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { mq } from "../../../styles"
import PartnerCard from "./PartnerCard";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const PartnerGridElement = styled.div`
    text-align: center;
    max-width: 970px;
    margin: 0 auto 20px;
    .all-partners{
      padding-top: 20px;
      @media screen and ${mq.minSm} {
        padding-top: 75px;
      }
    }
    a.all-partners-link {
      display: inline-block;
      padding: 10px 50px;
      text-decoration: none;
      text-align: center;
      font-size: 19px;
      font-weight: 700;
      transition: all .7s ease;
      border-radius: 25px;
      background: #286FF7;
      border-width: 5px;
      border-color: transparent;
      width: 90%;
      color: #fff;
      
      &:hover {
        text-decoration: none;
      }
      
      @media screen and ${mq.minSm} {
        width: auto;
      }
    } 
  `;

const PartnerGrid = ({featured}) => {
  const data = useStaticQuery(graphql`
    query {
      filtered: allPartners(filter: {showOnSite: {eq: true}, featured: {eq: true}}) {
        nodes {
          lp
          readable
          website
          partnerLogo {
            ...PartnerLogoCanvas
          }
        }
      }
      all: allPartners(filter: {showOnSite: {eq: true}}, sort: {order: ASC, fields: name}) {
        nodes {
          lp
          readable
          website
          partnerLogo {
            ...PartnerLogoCanvas
          }
        }
      }
    }
  `);

  const input = (featured ? data.filtered : data.all );

  const partners = input.nodes;

  const linkElm = <Link className={'all-partners-link'} to={'/partners/'}>Explore all our partners</Link>
  // <Button className={'all-partners-link'} label={'Explore all our partners'} destination={'/partners/'} type={'default'} />


  return (
    <>
      <PartnerGridElement>
        <div className="container all-partners">
          <div className="partners">
            { partners.map((partner, index) => {
              return <PartnerCard partner={partner} type={partner.type} key={index} />;
            })}
          </div>
          { (featured ? linkElm : '' ) }
        </div>
      </PartnerGridElement>
    </>
  );
};

PartnerGrid.propTypes = {
  featured: PropTypes.bool,
}

PartnerGrid.defaultProps = {
  featured: true,
}

export default PartnerGrid;


export const query = graphql`
  fragment PartnerLogoCanvas on File {
    childImageSharp {
      fluid(maxWidth: 150, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
