import React from "react";
import styled from "styled-components";
import GatsbyImage from "gatsby-image";

import { Content } from '../';
import { mq } from "../../../styles"

const Container = styled(Content)`

  padding: 25px 0 20px 0;
  max-width: 970px;
  margin: auto;

  @media screen and ${mq.maxMd} {
    padding: 25px 20px 20px 20px;
  }

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and ${mq.minSm} {
      flex-direction: row;
    }
  }
`

const ContentContainer = styled.div`
  margin-top: 50px;
  
  @media screen and ${mq.minMd} {
    margin-left: 3rem;
    margin-top: 0;
  }

  >h2 {
    text-align: left !important;
    font-size: 40px;

    @media screen and ${mq.maxMd} {
      text-align: center !important;
    }
  }

  >p {
    text-align: left !important;
    @media screen and ${mq.maxMd} {
      text-align: center !important;
    }
  }
`;

const ImgWrapper = styled.div`
  
  @media screen and ${mq.maxMd} {
    display: none;
  }
  
  .gatsby-image-wrapper {
    max-width: 350px;
    height: 350px;
    border-radius: 25px;
    overflow: hidden;
  }
`;

const ContentAside = ({children, image, imgStyle, style}) => {

  return (
    <Container>
      <div className="inner" style={{style}}>
        { ( image ? <ImgWrapper><GatsbyImage fixed={image.childImageSharp.fixed} imgStyle={imgStyle}/></ImgWrapper> : '' ) }
        <ContentContainer>
          { children }
        </ContentContainer>
      </div>
    </Container>
  )
}

export default ContentAside;
