import PropTypes from "prop-types";
import styled from 'styled-components';
import { mq } from '../../../styles/';
import React from "react";
import GatsbyImage from "gatsby-image";
import { graphql } from 'gatsby'
import { brand } from "../../../styles"

const ProfileContainer = styled.div`
  border-radius: 5px;
  box-shadow: ${brand.dropShadow};
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  
  @media screen and ${mq.minMd} {
    flex-direction: row;
    padding: 2rem;
  }
`;

const ProfileBioContainer = styled.div`
  @media screen and ${mq.minMd} {
    margin-left: 2rem;
  }
  
  *:last-child {
    margin-bottom: 0;
  }
`;

const ProfilePhotoContainer = styled.div`
  display: inline-block;
  width: 200px;
  margin: 0 auto;
  text-align: center;
`;

const Profile = ({ children, title, photo, name, linkedIn }) => {

  const JSONLD = {
    "@context": "https://schema.org",
    "@type": "Person",
    address: {
      "@type": "PostalAddress",
      addressLocality: "Toronto",
      addressRegion: "ON",
      postalCode: "M5H 2G4",
      streetAddress: "111 Richmond St. W.",
    },
    image: photo.childImageSharp.fixed.src,
    jobTitle: title,
    name: name,
    url: "https://www.points.com",
    sameAs: [linkedIn],
  };

  return (
    <>
      <ProfileContainer>
        <script type="application/json">{JSON.stringify(JSONLD)}</script>
        <ProfilePhotoContainer>
          <div className="profile-image" style={{
            position: 'relative',
          }}>
            <GatsbyImage
              imgStyle={{ objectPosition: "center center" }}
              style={{ height: 200, width: 200, borderRadius: '100%' }}
              fixed={photo.childImageSharp.fixed}
            />
            <LinkedInLink url={linkedIn} name={name} />
          </div>
          <h4 style={{ color: '#333', marginBottom: 0 }}>{name}</h4>
          <p style={{ marginBottom: '2rem' }}>{title}</p>
        </ProfilePhotoContainer>
        <ProfileBioContainer>{children}</ProfileBioContainer>
      </ProfileContainer>
    </>
  );
};

Profile.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string,
  title: PropTypes.string,
  photo: PropTypes.object,
  linkedIn: PropTypes.string,
};

Profile.defaultProps = {
  name: ``,
  title: ``,
  photo: ``,
};

function LinkedInLink(props) {
  const url = props.url;
  const name = props.name;

  if (url && name) {
    return <a style={{
      background: '#0077b5',
      position: 'absolute',
      right: 5,
      height: 75,
      width: 75,
      bottom: 5,
      borderRadius: '100%',
      display: 'block',
      textDecoration: "none",
      paddingTop: 15,
    }} href={url}  rel={'noreferrer'}>
      <svg width="45" height="45" viewBox="0 0 18 18" version="1.1">
        <g id="Page-1" stroke="none" fill="none">
          <path d="M12.907,5.406 C16.508,5.406 17.174,7.776 17.174,10.861 L17.174,17.147 L13.619,17.147 L13.619,11.578 C13.619,10.25 13.592,8.541 11.767,8.541 C9.914,8.541 9.631,9.986 9.631,11.48 L9.631,17.147 L6.077,17.147 L6.077,5.695 L9.491,5.695 L9.491,7.256 L9.537,7.256 C10.014,6.356 11.174,5.406 12.907,5.406 Z M3.845,5.695 L3.845,17.147 L0.281,17.147 L0.281,5.695 L3.845,5.695 Z M2.063,0 C3.203,0 4.127,0.925 4.127,2.063 C4.127,3.202 3.202,4.128 2.063,4.128 C0.919,4.128 4.4408921e-15,3.202 4.4408921e-15,2.063 C4.4408921e-15,0.925 0.92,0 2.063,0 Z" id="Combined-Shape" fill="#FFFFFF" />
        </g>
      </svg>
    </a>;
  } else {
    return '';
  }
}

export default Profile;

export const query = graphql`
  fragment TeamProfileFragment on File {
    childImageSharp {
      fixed(width: 300, height: 300) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`
