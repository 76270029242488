/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { brand, mq } from "../../../styles"
import styled, { css } from "styled-components";

const ContentElement = styled.section`
  padding: 0;
  position: relative;
  font-size: 19px;
  line-height: 32px;
  background-color: #fff;
  color: $595959;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 { color: #333; line-height: 1em; }
  
  h1 { font-size: 40px } 
  h2 { 
    font-size: 2.4rem;
    @media screen and ${mq.minMd} {
      font-size: 50px;
    } 
  } 
  h3 { font-size: 2em; } 
  h4 { font-size: 40px; } 
  h5 { font-size: 1em; } 
  h6 { font-size: 27px; } 
  
  
   a {
    color: #027bb6;
  }
  ${props => props.flexBlock && css`
  padding: 0% !important;
  @media screen and ${mq.minLg} {
    padding: 0 9% !important;
  }
  
  `}
  ${props => props.gradient && css`
    padding: 0 15%;
    overflow: hidden;
    z-index: 400;
    background: ${brand.gradient};
    color: #fff;
    @media screen and ${mq.maxMd} {
      padding: 25px 20px 20px 20px;
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 { color: #fff; }
  `}

  ${props => props.greyed && css`
    background-color: #f5f5f7;
  `}

  ${props => props.whiteOnGray && css`
  background-color: #fff;
  max-width: 1050px;
  margin: auto;
  padding-bottom:35px ;
  border-radius: 25px;
  margin-bottom: 30px;
  @media screen and (max-width: 1090px) {
    max-width: 95%;
  }
  @media screen and ${mq.maxMd} {
    padding-top:15px;
    // max-width: 90%;
  }
  
  
  h2{
    margin: 2rem auto;
    width: 70%;
    
  }
  .gatsbyHomepageDevices{

    @media screen and ${mq.maxMd} {
      display: none;
    }
  }
  .button-container{
    
    .index {
      display: block;
      text-align: center;
      margin: 10px 50px;
      @media screen and (min-width: 769px) and (max-width: 860px) {
        
        margin: 10px 40px;
        
      }
      a{
      width: 340px;
      @media screen and (min-width: 769px) and (max-width: 860px) {
        width: 100%;
        margin: 10px auto;
        padding: 10px 40px;
      }
      @media screen and (max-width: 450px) {
        width: 90%;
        padding: 10px 10px 12px;
      }
    }
    @media screen and ${mq.maxSm} {
      margin: 10px auto;
    }
  }
    
    @media screen and ${mq.minSm} {
      
      display: flex;
      justify-content: center;
    }
  }
  `}

  ${props => props.index && css`
  margin: 1rem 4rem 2rem;
  
  @media screen and ${mq.minSm} {
    margin: 75px auto;
    max-width: 1050px;
  }
 
  `}
  ${props => props.lcrPage && css`
  padding-bottom: 0px;
    margin: 70px auto 100px auto;
    &.keepContent { margin: 250px auto 200px; }
  @media screen and (max-width: 991px) {
    margin: 20px;
    &.keepContent { margin: 20px; }
  }  
  `}
`;

/**
 * This is our basic content container element.
 */
const Content = ({ children, contained, angled, ...props}) => (
    <ContentElement angled={angled} {...props}>
      { (contained ? <div className="container">{children}</div> : {children}) }
    </ContentElement>
);

Content.propTypes = {
  contained: PropTypes.bool,
  angled: PropTypes.bool
};

Content.defaultProps = {
  contained: true,
  angled: false
};

export default Content;
