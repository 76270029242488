import PropTypes from "prop-types";
import Logo from './Logo';

import React, { useEffect, useState } from "react"
import navData from "../../../../content/menu";
import frnavData from "../../../../content/frmenu";
import styled, { css } from "styled-components";
import Menu from "./Menu";
import { brand, mq } from "../../../styles";

const HeaderElm = styled.header`
  position: fixed;
  z-index: 900;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  transition: background .7s ease;
  border-bottom: 1px solid transparent;
  background: #fff;
  border-bottom-color: #ccc;

  #tagline,
  #points-word-mark {
    fill: #636567;
  }

  .dark-logo {
    display: inline-block;
  }
  .light-logo {
    display: none !important;
  }

  button {
    transition: all .5s ease;
    background: ${brand.colors.pts_blue_dark};
    text-decoration: none;
  }
  
  
  
`;

const Header = ({wallet}) => {

  let menuNavData; 
  if (typeof window !== 'undefined') {
    
  let currentURlContainsFR = window.location.href.indexOf("fr.") > -1;
  if (currentURlContainsFR) {
    menuNavData = frnavData;
  }
  else {
    menuNavData = navData;
  }
  }
  else{
    menuNavData = navData;
  }

  const [open, setOpenState] = useState(false);
  const [top, setPos] = useState(false);
  let lightLogo = true;

  useEffect (()=>{
    document.addEventListener("scroll", e => {
      let scrolled = document.scrollingElement.scrollTop;
      setPos((scrolled >= 20));
    });
    document.addEventListener("touchmove", e => {
      let scrolled = document.scrollingElement.scrollTop;
      setPos((scrolled >= 20));
    });

  },[]);

  const data = navData;
  // console.log(data)
  return (
    <div className="header-banner">
    <HeaderElm className="1" affixed={top} open={open}>
    <Logo navData={navData} className="2"/>
      <MenuToggle open={open} affixed={top}  onFocus={() => setOpenState(!open)} onClick={() => setOpenState(!open) } aria-label={'Menu toggle button'}>
        <span />
        <span />
        <span />
        <span />
      </MenuToggle>
      <Menu data={data} open={open} affixed={top} />
     
    </HeaderElm>
    </div>
  );
}

Header.propTypes = {
  navData: PropTypes.bool,
};

Header.defaultProps = {
  navData: false,
};

export default Header;

const MenuToggle = styled.button`
  display: block;
  background: transparent;
  padding: 10px 8px;
  border-radius: 35px;
  border: 0;
  margin-left: 1rem;
  position : absolute;
  right: 5%;
  
  span {
    height: 2px;
    display: block;
    width: 20px;
    border-radius: 2px;
    background: #fff;
    transition: all .5s ease;
    
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
  
  &:hover span {
    background: #fff;
  }
  
  ${props => props.affixed && css`
    span { background: #fff; }
  `}
  
  ${props => props.open && css`
    span { background: #fff; }
  `}
 
  span:nth-child(3) { margin-top: -7px; }
  
  ${props => props.open && css`
    span:nth-child(1) { opacity: 0 }
    span:nth-child(2) { transform: rotate(45deg); }
    span:nth-child(3) { transform: rotate(-45deg); }
    span:nth-child(4) { opacity: 0 }
  `}
    
  @media screen and ${mq.minMd} {
    display: none;
  }
`;
