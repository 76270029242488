import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { mq } from '../../../styles';

const stats_lead = styled.div``;

const stats_prefix = styled.span``;
const stats_suffix = styled.span``;
const stats_value = styled.span``;
const stats_trail = styled.div``;

const StatsBlockInner = styled.div`
    font-size: 30px;
    line-height: 1;
    font-weight: bold;
  
    @media screen and ${mq.minMd} {
      font-size: 80px;
    }
`;

const StatisticBlockElement = styled.div`
  width: 100%;
  display: inline-block;
  color: #fff;
  padding-bottom: 20px;
`;

const StatisticBlock = ({ value, prefix, suffix, lead, trail, ...props }) => {
  return (
    <>
      <StatisticBlockElement {...props}>
        <div>
          <stats_lead>{lead}</stats_lead>
          <StatsBlockInner>
            <stats_prefix>{prefix}</stats_prefix>
            <stats_value>{value}</stats_value>
            <stats_suffix>{suffix}</stats_suffix>
          </StatsBlockInner>
          <stats_trail>{trail}</stats_trail>
        </div>
      </StatisticBlockElement>
    </>
  );
};

StatisticBlock.propTypes = {
  value: PropTypes.number.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  lead: PropTypes.string,
  trail: PropTypes.string,
};

StatisticBlock.defaultProps = {
  value: '',
  prefix: '',
  suffix: '',
  lead: '',
  trail: '',
}

export default StatisticBlock;
