import styled, { css } from "styled-components";
import { mq, brand } from "../../styles"

const StaggeredColumn = styled.div`
  margin: auto;
  margin-top: 0;
  width: 90%;
  max-width: 1050px;
  padding: 50px 0;
  background-color: #f5f5f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media screen and ${mq.maxMd} {
    ${props => props.home && css`
      margin-top: 0px;
    `}
  }


  @media screen and ${mq.minSm} {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    ${props => props.careers && css`
      padding-bottom: 150px;
    `}
  }
// &.img-block .tileimagecontent h4{
//   width: 55%;
//   margin: auto;
// }
&.img-block .tileimagecontent p{
  width: 65%;
  font-size: 19px;
  margin: 15px auto 0px auto;
}
  > div {
    height: 350px;
    width: 350px;

    @media screen and ${mq.maxMd} {
      width: 90%;
      max-width: 350px;
      height: 420px;
    }

    ${props => props.home && css`
      flex-basis: 48%;
      height: 450px;
      @media screen and (max-width: 1090px) {
        flex-basis: 46%;
      }
      @media screen and ${mq.maxMd} {
        width: 90%;
        max-width: 520px;
        height: 530px;
      }
      @media screen and ${mq.maxSm} {
        flex-basis: 95%;
        &:last-child {
          display: none;
        }
      }
      @media screen and (max-width: 767px) {
        margin: 15px;
        height: 480px; 
      }

    `}

    ${props => props.careers && css`
      width: 350px;
      flex-basis: 48%;
      height: 480px; 
    `}

    ${props => props.staggered && css`
    &:nth-child(even) {
      transform: translateY(120px);

      @media screen and ${mq.maxSm} {
        transform: translateY(0);
      }

    `}
  }
`;

export default StaggeredColumn;
