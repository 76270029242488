module.exports = [
  {
    title: 'About Us',
    url: '/about/'
  },
  {
    title: 'Products',
    submenu: [
      {
        title: 'Currency Retailing',
        url: '/products/loyalty-currency-retailing/',
      },
      {
        title: 'Everyday Earn & Burn',
        url: '/products/everyday-earn-and-burn/'
      },
    ]
  },
  {
    title: 'Partners',
    url: '/partners/'
  },
  {
    title: 'Careers',
    url: '/current-openings/'
    // submenu: [
    //   {
    //     title: 'Careers',
    //     url: '/careers/',
    //   },
    //   {
    //     title: 'Current Openings',
    //     url: '/careers/current-openings/'
    //   },
  //   ]
  },
  {
    title: 'News & Insights',
    url: 'https://news.points.com'
  },
  {
    title: 'Contact',
    url: '/contact/'
  }
];
