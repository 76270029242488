import import_greycliff from "./fonts/greycliff.font";

export const brand = {
  gradient: 'linear-gradient(225deg, #4DC9EE 0%, #286FF7 100%)',
  dropShadow: '0 0 11px 0 #0002',
  colors: {
    pts_blue_dark: '#286FF7',
    pts_blue_light: '#40c8f4',
    pts_pink: '#eb0055',
    main_background: '#FFFFFF',
    second_background: '#f5f5f7',
  }
}

export const font = {
  family: {
    greycliff: `'Greycliff', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
  },
  imports: {
    greycliff: import_greycliff,
  }
}

export const size = {
  xs: '480px',
  sm: '768px',
  md: '992px',
  lg: '1200px',
  xl: '1400px'
}

export const mq = {
  minXs: `(min-width: ${size.xs})`,
  minSm: `(min-width: ${size.sm})`,
  maxSm: `(max-width: ${size.sm})`,
  minMd: `(min-width: ${size.md})`,
  maxMd: `(max-width: ${size.md})`,
  minLg: `(min-width: ${size.lg})`,
  minXl: `(min-width: ${size.xl})`,
};
