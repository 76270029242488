import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components"
import { brand, mq } from "../../../styles"

const CallToActionElm = styled.section`
	position: relative;
	color: #fff;
	padding: 1rem 1rem 2rem 1rem;
	background: ${brand.gradient};

	@media screen and (min-width: 700px) {
		padding: 4rem 0;
	}
	
	.container {
	  max-width: 890px;
	  text-align: center;
	}
	
	h4 {
	  margin-bottom: 15px;
	  font-size: 27px;
	  font-weight: 500;
	  
	  @media screen and ${mq.minMd} {
	    font-size: 50px;
		white-space: pre-wrap;
	  }
	  
	}
`;

const CallToAction = ({ children, title }) => {

  return (
    <>
      <CallToActionElm>
        <div className="container">
          <h4>{ title }</h4>
          <div className="inner">{children}</div>
        </div>
      </CallToActionElm>
    </>
  );
};

CallToAction.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

CallToAction.defaultProps = {
  type: `basic`,
  background: `to-king-street.jpg`,
};

export default CallToAction;
