import React from "react";
import styled,{ css } from "styled-components";
import { Content } from "../";
import GatsbyImage from "gatsby-image"
import { mq } from "../../../styles"
import { graphql } from "gatsby"

const BlueContainer = styled.div`
color: #fff;
margin: 25px auto 5px auto;
max-width: 1050px;
.lcrPage{
> div {
  margin-bottom: 4rem;
}
  @media screen and ${mq.minSm} {
    display: flex;
    justify-content: space-around;
  }
}
`;
const CallOutText = styled.div`

  // *:last-child { margin-bottom: 0 }
  
`;

const IconBox = styled.div`
  text-align: center;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-self: auto;
  margin-top: auto;

  
  
`;
// const Content = styled.div`
//   padding: 0%;
// `;

const BlueBlock = ({icon,children, attribute, ...props}) => {

  return (
    <Content gradient flexBlock>
      <BlueContainer {...props}>
        <CallOutText>
          { children }
        </CallOutText>
        { (icon ? <IconBox><GatsbyImage fixed={icon.childImageSharp.fixed} /></IconBox> : '') }
      </BlueContainer>
      </Content>
  )
}

export default BlueBlock;

export const query = graphql`
  fragment IconCalloutImage on File {
    childImageSharp {
      fixed(width: 150, height: 150, webpQuality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`;
