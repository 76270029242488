import { createGlobalStyle } from "styled-components";
import { mq, brand, font } from "./variables"
import reset from "./utilities/reset"

export default createGlobalStyle`

  ${ reset }

  ${ font.imports.greycliff } 
  ${ font.imports.museoSans }
  

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${font.family.greycliff};
    position: relative;
    color: #333333;
    font-weight: normal;
    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
    font-size: 18px;
  }
  .header-banner{
    // height:200px;
  }
  .border {
    width: 100%;
    max-width: 800px;
    border-top: 1px solid #595959;
  }
  .sunset-banner{
    background-color: #E5E5E5;
    position:fixed;
    z-index: 900;
    color: #000000;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    min-height: 100px;
    max-height: 230px;
    padding-left:20px;
    @media screen and (max-width: 900px) {
      font-size: 15px;
    }
    .right-copies {
      .copy-one{
        font-weight: bold;
      }
      @media screen and (max-width: 1050px) {
        margin: 8px;
      }
    }
  }
  div[data-partner-name="World of Hyatt"] {
   > a > div.gatsby-image-wrapper > div {
    padding-bottom: 46% !important;
   }
  }
  .container {
    margin: 0 auto;
    width: 100%;
    position: relative;
  
    &-padding {
      position: relative;
      padding-left: 1rem;
      padding-right: 1rem;
  
      @media screen and ${mq.minMd} {
        padding-left: calc(((100vw - 1120px) / 2) + 1.0875rem);
        padding-right: calc(((100vw - 1120px) / 2) + 1.0875rem);
      }
    }

    p {
      text-align: center;
    }
  }
  .IHG-wallet{
    height: 100px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    font-weight: 800;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
  }
  
  .spaced-list {
    li {
      margin-bottom: 1rem;
    }
  }
  
  .btn,
  button {
    border: 1px solid #ccc;
    background: white;
    color: $pts_blue;
    font-weight: bold;
  }
  
  blockquote {
    border-left: 5px solid #ccc;
    padding-left: 10px;
  }
  blockquote > * {
    margin-bottom: 0;
  }
  
  .ff-mono { font-family: monospace; }
  .ff-serif { font-family: serif; }
  
  .kl {
    text-align: center;
  }

  .kl > p {
    text-align: center;
  }

  .partner-icon-list {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    gap: 40px 60px;
    width: 90%;
    max-width: 970px;
    margin: auto;
    align-items: center;
    justify-content: space-evenly;
    margin: 50px auto;

    div {
      flex-basis: 150px;
      height: 60px;
    }
  }
  
  ul.pts-checkmark-list {
    margin-bottom: 1rem;
    display : flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0 5px;
    margin-left: 0px;
    @media only screen and (max-width: 500px) {
      justify-content: center;
    }
    li {
      list-style: none;
      margin-bottom: .5rem;
      margin-right: auto;
      &.transfer::before{
        background-image: url('/assets/PI-Transfer.png');
      }
      &.gift::before{
        background-image: url('/assets/PI-Gift.png');
      }
      &.exchange::before{
        background-image: url('/assets/PI-Exchange.png');
      }
      &::before {
       content: '';
       display: block;
       margin: auto auto 5px;
       height: 30px;
       width: 30px;
       background-size: contain;
       background-image: url('/assets/pts-checkmark.png');
       background-repeat: no-repeat;
      }
    }
    
    &-white {
      li {
      &.accelerate::before {
        // margin: auto 30px;
        background-image: url('/assets/PI-Accelerate.png');
      }
      &.sell::before {
        background-image: url('/assets/PI-Buy.png');
      }
      &.topup::before {
        background-image: url('/assets/PI-Topup.png');
      }
      &.elite::before {
        background-image: url('/assets/PI-Elite.png');
      }
      &.reinstate::before {
        background-image: url('/assets/PI-Reinstate.png');
      }
      &.extend::before {
        background-image: url('/assets/PI-Extend.png');
        
      }
    }
    }
  }
  
  .grid {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    
    @media screen and ${mq.minMd} {
      flex-direction: row;
      justify-content: space-between;
    }
    
    &-3x {
      @media screen and ${mq.minMd} {
        .col,
        > div {
          width: 32%;
        }
      } 
    }
    &.index {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;
      @media screen and ${mq.maxSm} {
        display: flex;
    flex-direction: column;
    flex-wrap: wrap;
      }
    }
    &-2x {
      @media screen and ${mq.minMd} {
        .col,
        > div {
          width: 48%;
        }
      }
    }
    
    &.offset {
      >:nth-child(even) {
        transform: translateY(20px);
      }
    }
  } 
  
  .max-w-none { 	max-width: none; }
  .max-w-xs { 	max-width: 20rem; }
  .max-w-sm { 	max-width: 24rem; }
  .max-w-md { 	max-width: 28rem; }
  .max-w-lg { 	max-width: 32rem; }
  .max-w-xl { 	max-width: 36rem; }
  .max-w-2xl { 	max-width: 42rem; }
  .max-w-3xl { 	max-width: 48rem; }
  .max-w-4xl { 	max-width: 56rem; }
  .max-w-5xl { 	max-width: 64rem; }
  .max-w-6xl { 	max-width: 72rem; }
  .max-w-full { 	max-width: 100%; }
  
  a {
    text-decoration: none;
    color: inherit;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  .gatsby-image-wrapper {
    max-width: 100%;
  }
  
  .lg-only {
    display: none;

    @media screen and ${mq.minMd} {
      display: block !important;
    }
  }

  .desktop-vis {
    display: flex !important;

    @media screen and ${mq.maxSm} {
      display: none !important;
    }
  }

  .mob-icon-details {
    width: 90%;
    height: 80vw;
    max-height: 400px;
    max-width: 400px;
    margin: 20px auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 26px;
    gap: 10px 0;

    p {
		  white-space: pre-wrap;

      .percentage {
        font-size: 50px;
        color: #4DC9EE;
        font-weight: 900;
        line-height: initial;
      }
      span {
        display: block;
		    white-space: pre-wrap;
      }
    }
  }
  .mobile-vis {
    display: none !important;

    @media screen and ${mq.maxSm} {
      display: flex !important;
    }
  }

  .location-bg {
    width: 70%;
    @media screen and ${mq.maxMd} {
      width: 90%;
    }
  }

  .content-section-header {
    text-align: left;
    @media screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  .desktop-break {
    display: block;
    @media only screen and (max-width: 460px) {
      display: none;
    }
  }

  .mobile-space {
    display: none;
    @media only screen and (max-width: 460px) {
      display: inline;
    }
  }

  .move {
    overflow: visible !important;
    &:after{
      content: '';
      position: absolute;
      top: 91%;
      left: -90px;
      width: 43px;
      height: 230px;
      background-image: url('/assets/connector.png');
      background-size: contain;
      background-repeat: no-repeat;

    }
  }

  .get {
    overflow: visible !important;
    &:after{
      content: '';
      position: absolute;
      top: 91%;
      right: -120px;
      width: 43px;
      height: 230px;
      background-image: url('/assets/connector.png');
      background-size: contain;
      background-repeat: no-repeat;

    }
  }

  .get-image-con{
    margin-bottom: 250px!important;

    @media only screen and (max-width: 991px) {
      margin-bottom: initial !important;
    }
  }
`;