import React from "react";
import styled, { css } from "styled-components";
import { mq } from "../../../styles"

const GridContainer = styled.div`
  width: 70%;
  max-width: 1050px;
  margin: auto;
  padding-top: 46px;
  padding-bottom: 10px;
  background-color: #fff;
  @media screen and ${mq.minMd} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  ${props => props.form && css`
    padding-top: 10px;
    width: 74%;
  `}
  
  ${props => props.columns === 2 && css`
  @media screen and (max-width: 991px) {
  div.right-content{
    margin-left: 25px;
  }
  div.left-content{
    margin-bottom: 0px;
  }
}
    @media screen and ${mq.minMd} {
      div.left-content{
        width: 40% !important;

      }
      div.right-content{
        width: 55% !important;

        &.keep-right-content {
          width: 43% !important;
        }
      }
      .col,
      > div: first-of-type {
        width: 35%;
      }
      > div: last-of-type {
        width: 55%;
      }
    }
  `}
  
  ${props => props.columns === 3 && css`
  
    @media screen and ${mq.minMd} {
      .col,
      > div {
        width: 32%;
      }
    }
  `}
  ${props => props.lcrGrid && css`
    width: 100%;
    border-radius: 25px;
    padding-top: 25px;
  `}
  
`;

const ColumnContainer = styled.div`
  margin-bottom: 2rem;
  max-width: 100%;
  > .lcrColImage{
    max-width: 70%;
    margin: -90px auto;
     z-index: 500;

     &.get {
      width: 294px !important;
      max-width: initial !important;
     }
     
     &.move{
      width: 464px !important;
      max-width: initial !important;
      margin: -40px auto -60px;
    }
     &.keep{margin: -180px auto;}
     @media screen and (max-width: 991px) {
      display: none;
      margin-bottom: 0rem;
    }


  }
  @media screen and ${mq.minMd} {
    margin-bottom: 0;
  }

  
`;

const Grid = ({ columns, children, ...props }) => {
  return (
    <GridContainer columns={columns} {...props}>
      { children }
    </GridContainer>
  )
}

const Column = ({ children, ...props }) => (
  <ColumnContainer {...props}>
    { children }
  </ColumnContainer>
);

export { Grid, Column };
