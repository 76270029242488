import { css } from "styled-components";
import { withPrefix } from "gatsby";

let basePath = '/fonts/';

try {
  basePath = withPrefix('/fonts/');
} catch (error) {
  // ReferenceError: __BASE_PATH__ is not defined
  // console.log(error);
}

const greycliff = css`

@font-face {
	font-family: 'Greycliff';
	font-style: normal;
	font-display: swap;
	src: url(${basePath}greycliff/GreycliffCF-Medium.eot);
	src: url(${basePath}greycliff/GreycliffCF-Medium.eot?#iefix) format('embedded-opentype'),url(${basePath}greycliff/GreycliffCF-Medium.woff2) format('woff2'),url(${basePath}greycliff/GreycliffCF-Medium.woff) format('woff')
}

@font-face {
	font-family: 'Greycliff';
	font-weight: 400;
	font-style: oblique;
	font-display: swap;
	src: url(${basePath}greycliff/GreycliffCF-MediumOblique.eot);
	src: url(${basePath}greycliff/GreycliffCF-MediumOblique.eot?#iefix) format('embedded-opentype'),url(${basePath}greycliff/GreycliffCF-MediumOblique.woff2) format('woff2'),url(${basePath}greycliff/GreycliffCF-MediumOblique.woff) format('woff')
}

@font-face {
	font-family: 'Greycliff';
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	src: url(${basePath}greycliff/GreycliffCF-Bold.eot);
	src: url(${basePath}greycliff/GreycliffCF-Bold.eot?#iefix) format('embedded-opentype'),url(${basePath}greycliff/GreycliffCF-Bold.woff2) format('woff2'),url(${basePath}greycliff/GreycliffCF-Bold.woff) format('woff')
}

@font-face {
	font-family: 'Greycliff';
	font-weight: 700;
	font-style: oblique;
	font-display: swap;
	src: url(.${basePath}greycliff/GreycliffCF-BoldOblique.eot);
	src: url(.${basePath}greycliff/GreycliffCF-BoldOblique.eot?#iefix) format('embedded-opentype'),url(${basePath}greycliff/GreycliffCF-BoldOblique.woff2) format('woff2'),url(${basePath}greycliff/GreycliffCF-BoldOblique.woff) format('woff')
}

@font-face {
	font-family: 'Greycliff';
	font-weight: 900;
	font-style: normal;
	font-display: swap;
	src: url(.${basePath}greycliff/GreycliffCF-Heavy.eot);
	src: url(.${basePath}greycliff/GreycliffCF-Heavy.eot?#iefix) format('embedded-opentype'),url(${basePath}greycliff/GreycliffCF-Heavy.woff2) format('woff2'),url(${basePath}greycliff/GreycliffCF-Heavy.woff) format('woff')
}

@font-face {
	font-family: 'Greycliff';
	font-weight: 900;
	font-style: oblique;
	font-display: swap;
	src: url(${basePath}greycliff/GreycliffCF-HeavyOblique.eot);
	src: url(${basePath}greycliff/GreycliffCF-HeavyOblique.eot?#iefix) format('embedded-opentype'),url(${basePath}greycliff/GreycliffCF-HeavyOblique.woff2) format('woff2'),url(${basePath}greycliff/GreycliffCF-HeavyOblique.woff) format('woff')
}
`;

export default greycliff;
