import React from "react";

import PropTypes from "prop-types";
import { Link } from "gatsby";
import styled, { css } from "styled-components"
import { brand, mq } from "../../../styles"

const ButtonElement = styled.span`
  display: inline-block;
  
  a {
    display: inline-block;
    padding: 10px 50px;
    text-decoration: none;
    text-align: center;
    font-size: 19px;
    font-weight: 700;
    transition: all .7s ease;
    border-radius: 100px;
    border-width: 5px;
    border-color: transparent;
    width: 100%;
    color: inherit;
    
    &:hover {
      text-decoration: none;
    }


	  
	  @media screen and ${mq.minSm} {
      width: auto;
    }

    ${(props) => props.hero && css`
      @media screen and ${mq.maxSm} {
        margin-bottom: 25px;
      }
    `}
    
    ${props => props.type === 'default' && css`
      background: ${brand.colors.pts_blue_dark};
      border: 1px solid darken(${brand.colors.pts_blue_dark}, 10%);
      color: white;
  
      &:hover {
        color: white;
		    background: rgba(${brand.colors.pts_blue_dark}, .8);
      }
    `}
  
    ${props => props.type === 'white' && css`
      background: #fff;
      border-color: darken(#fff, 10%);
      color: ${brand.colors.pts_blue_dark};
  
      &:hover {
        color: ${brand.colors.pts_blue_dark};
        background: rgba(#fff, .8);
      }
    `}
    
    ${props => props.type === 'outline' && css`
      background: transparent;
      border: 3px solid #fff;
      color: #fff;
  
      &:hover {
        background: #0005;
      }
    `}
  
    ${props => props.type === 'accent' && css`
      background: #FD0D5B;
      border-color: darken(#FD0D5B, 10%);
      color: #fff;
  
      &:hover {
        color: #fff;
        border-color: darken(#FD0D5B, 20%);
      }
    `}
  }
`;

const Button = ({ type, label, destination, children, target, rel, ...props }) => {

  const isExternalLink = (destination.indexOf('http') !== -1 );

  const internalLink = <Link target={target} rel={rel} data-type={'gatsby-link'} to={destination}>{label}</Link>;
  const externalLink = <a target={target} rel={rel} href={destination}>{label}</a>;

  return (
    <ButtonElement type={type} {...props}>
      { isExternalLink ? externalLink : internalLink }
    </ButtonElement>
  )
};

Button.propTypes = {
  label: PropTypes.any.isRequired,
  destination: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["white", "default", "accent", "outline"]),
};

Button.defaultProps = {
  label: 'Click here',
  destination: '#',
  type: "default",
};

export default Button;
